import Helmet from "react-helmet"
import React from "react"
import ThankYouPage from "src/components/modules/typNew"
import Data from "./data/data.json"
import SiteData from "branding/site-metadata.json"


export default function ThankYou() {
  return(
    <>
    <Helmet>
      <style type="text/css">{`
        .typ-module--typContainer h1.typ-module--typHL {
          margin: 30px auto;
          width: 100%;
        }

        footer {
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          font-size: 12px;
          color: #666;
          }

          footer a {
            color: #666;
          }
        }
        `}
        </style>
        <title>Thank You for Downloading Template Search</title>
      </Helmet>
    <section>
      <ThankYouPage data={Data} siteData={SiteData}></ThankYouPage>
    </section>
    </>
  )
}
